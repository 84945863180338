import React from 'react'
import { graphql } from 'gatsby'

import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from '../utils/helpers'
import WorkFeatureList from '../components/work-feature-list'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
/* import styles from './index.module.css' */

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      ogImage {
        asset {
          url
        }
      }
      keywords
    }
    pages: allSanityPages {
      nodes {
        posts {
          id
        }
      }
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          categories {
            _id
            title
          }
          mainImage {
            ...SanityImage
            alt
          }
          isJournalEntry
          showCategories
          forceFullWidth
          title
          slug {
            current
          }
          postAtRoot
          excerpt
          headerBgColor {
            hex
          }
          headerLinkColor {
            hex
          }
          headerTitleColor {
            hex
          }
          documentColor {
            hex
          }
          headerIframe
          headerImage {
            image {
              asset {
                _id
              }
            }
            width
            alt
          }
          headerLinks {
            url
            title
          }
        }
      }
    }
  }
`

const IndexPage = (props) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const pageNodes = (data || {}).pages
  const sortedPostNodes = []
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }
  for (const pagePost of pageNodes.nodes[0].posts) {
    for (const post of postNodes) {
      if (post.id === pagePost.id) {
        sortedPostNodes.push(post)
      }
    }
  }
  return (
    <Layout>
      <SEO
        title="Home"
        description={site.description}
        keywords={site.keywords}
        image={site.ogImage.asset.url}
      />
      {postNodes && <WorkFeatureList nodes={sortedPostNodes} />}
    </Layout>
  )
}

export default IndexPage
